import React, { Fragment } from 'react'
import { BaseComponent, InputComponent as Input } from '../components/index'
import {
  Login as LoginFunc,
  Register as RegisterFunc,
} from '../common/ApiHelper'
import { Tabs } from 'antd'

class Login extends BaseComponent {
  public charWidthRegister = '4em'
  public charWidthLogin = '5em'
  state = {
    TabIndex: 0,
    account: '',
    password: '',
    countname: '',
  }
  public TabOnClick = (Index: string) => {
    this.setState({ TabIndex: parseInt(Index) })
  }
  public OnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget
    const _state = this.state
    let state: Partial<typeof _state> = {}
    switch (name) {
      case 'countRegister':
      case 'countLogin':
        state = { account: value }
        break
      case 'pwdRegister':
      case 'pwdLogin':
        state = { password: value }
        break
      case 'nameRegister':
        state = { countname: value }
    }
    this.setState(state)
  }
  /**
   * 登录
   */
  public OnClickLogin = () => {
    const { account, password } = this.state
    this.ShowToast('登录中....', 0)
    LoginFunc(account, password, (res) => {
      this.RemoveToast()
      if (res.code === 0) {
        this.ShowToast(res.emsg, 1, 2000)
        return
      }
      localStorage.setItem('userID', res.Date.usercode)
      window.userID = res.Date.usercode
      let rd = this.GetQueryString(this.props.location.search, 'rd')
      rd = rd === null ? '/' : rd
      this.props.history.push(rd)
    })
  }
  /**
   * 注册
   */
  public OnClickRegister = () => {
    const { account, password, countname } = this.state
    this.ShowToast('注册中....', 0)
    RegisterFunc(account, password, countname, (res) => {
      this.RemoveToast()
      if (res.ResultCode === 0) {
        this.setState({
          TabIndex: 0,
        })
        this.ShowToast(res.Msg, 1, 2000)
      } else {
        this.ShowToast(res.Msg, 1, 2000)
      }
    })
  }

  /**
    rendername
     */
  public render() {
    const { charWidthLogin, OnChange, OnClickLogin, OnClickRegister } = this
    const { TabIndex, account, password, countname } = this.state
    let BodyContent
    switch (TabIndex) {
      case 0: //登录
        BodyContent = (
          <Fragment>
            <Input
              name="countLogin"
              key="countLogin"
              title="账号"
              placeholder="请输入账号"
              width={charWidthLogin}
              type="text"
              value={account}
              maxLength={11}
              onChange={OnChange}
            />
            <Input
              name="pwdLogin"
              key="pwdLogin"
              title="密码"
              type="password"
              placeholder="请输入密码"
              width={charWidthLogin}
              value={password}
              maxLength={20}
              onChange={OnChange}
            />
            <span className="btn" onClick={OnClickLogin}>
              登 录
            </span>
          </Fragment>
        )
        break
      case 1: //注册
        BodyContent = (
          <Fragment>
            <Input
              name="countRegister"
              key="countRegister"
              title="账号"
              placeholder="请输入账号"
              width={charWidthLogin}
              type="text"
              value={account}
              maxLength={11}
              onChange={OnChange}
            />
            <Input
              name="nameRegister"
              key="nameRegister"
              title="用户名"
              type="text"
              placeholder="请输入用户名"
              width={charWidthLogin}
              value={countname}
              maxLength={20}
              onChange={OnChange}
            />
            <Input
              name="pwdRegister"
              key="pwdRegister"
              title="密码"
              type="password"
              placeholder="请输入密码"
              width={charWidthLogin}
              value={password}
              maxLength={20}
              onChange={OnChange}
            />
            <span className="btn" onClick={OnClickRegister}>
              注 册
            </span>
          </Fragment>
        )
        break
    }
    return (
      <div className="c-box">
        <div className="c-banner1">
          <img src={require('../img/banner1.jpg')} />
        </div>
        <Tabs
          size="large"
          type="card"
          defaultActiveKey={TabIndex.toString()}
          activeKey={TabIndex.toString()}
          onChange={this.TabOnClick}
        >
          <Tabs.TabPane tab="登录" key="0"></Tabs.TabPane>
          {/* <Tabs.TabPane tab="注册" key="1"></Tabs.TabPane> */}
        </Tabs>
        <div className="c-index-menu">
          <div className="login">
            <div className="head"></div>
            <div className="body">{BodyContent}</div>
          </div>
        </div>
      </div>
    )
  }
}
export default Login
