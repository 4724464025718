import React from 'react'
import Swiper from './components/SwiperComponent'
import './App.css'
import 'antd/dist/antd.css'
import BaseComponent from './components/BaseComponent'
import { GetLookHome, NewArrivals } from './common/ApiHelper'
import Class from './components/ClassComponent'
import Card from './components/CardComponent'

const imgs = [
  {
    id: '1',
    src: require('./img/banner3.jpg'),
  },
  {
    id: '2',
    src: require('./img/banner3.jpg'),
  },
  {
    id: '3',
    src: require('./img/banner3.jpg'),
  },
]

class App extends BaseComponent {
  public state: {
    slideData: Array<{ imgUrl: string; bookID: string; bookType: string }>
    classData: Array<{
      id: string
      src: string
      name: string
      items: Array<{ id: string; src: string; name: string }>
    }>
    searchText: string
    //booksData:Array<{SakeyiID:string,tsmc:string, fmtj:string,}>,新书推荐
  } = {
    slideData: [],
    classData: [],
    searchText: '',
    //booksData:[]
  }
  public type = '1'
  public componentDidMount() {
    this.ShowToast()
    GetLookHome(
      this.type,
      (res) => {
        //返回读书首页数据
        const slideData = res.Data.slideShow.map((v) => {
          return {
            id: v.bookID,
            src: v.imgUrl,
          }
        })
        const classData = res.Data.classifies.slice(0, 22).map((classifie) => {
          const items = classifie.items.map((item) => {
            return {
              id: item.bookID,
              src: item.imgUrl,
              name: item.name,
            }
          })
          return {
            id: classifie.classifyID,
            src: classifie.imgUrl,
            name: classifie.name,
            items: items,
          }
        })
        this.setState({ slideData: slideData, classData: classData }, () => {
          this.RemoveToast()
        })
      },
      () => {
        this.RemoveToast()
      }
    )
  }
  public onMoreClick = (id: string) => {
    this.props.history.push('/Search?classifyid=' + id + '&type=' + this.type)
  }
  public onItemClick = (id: string) => {
    switch (this.type) {
      case '1':
        this.props.history.push('/Details?bookid=' + id)
        break
    }
  }
  public onClassItemClick = (id: string) => {
    this.props.history.push('/Search?classifyid=' + id + '&type=' + this.type)
  }
  public Searching = () => {
    //搜索获取跳转链接
    this.props.history.push(
      '/Search?keyword=' + this.state.searchText + '&type=' + this.type
    )
  }
  public loginOut = () => {
    localStorage.removeItem('userID')
    this.props.history.push('/Login')
  }
  public SearchInputOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchText: e.currentTarget.value })
  }
  public onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.keyCode === 13) {
      //快捷Enter搜索
      this.Searching()
    }
  }
  public render() {
    const { slideData, classData, searchText } = this.state
    const classDatalist = classData.map((data) => {
      return (
        <Card
          key={data.id}
          data={data.items}
          id={data.id}
          name={data.name}
          onItemClick={this.onItemClick}
          onMoreClick={this.onMoreClick.bind(this, data.id)}
        />
      )
    })
    return (
      <div className="c-box">
        {/* 头部 */}
        <div className="c-top1">
          <div className="c-search">
            <input
              type="text"
              onKeyDown={this.onKeyDown}
              value={searchText}
              onChange={this.SearchInputOnChange}
              placeholder="请输入图书名称"
            />
            <a href="#">
              <img src={require('./img/search.png')} onClick={this.Searching} />
            </a>
          </div>
          <a className="c-shujia" href="#" onClick={this.loginOut}>
            <img src={require('./img/shujia.jpg')} />
          </a>
        </div>
        {/* 头部 */}
        <Swiper data={imgs} />
        <Class data={classData} onItemClick={this.onClassItemClick} />
        {classDatalist}
      </div>
    )
  }
}

export default App
