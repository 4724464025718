import React, { PureComponent } from 'react';
import AsyncImg from '../components/AsyncImgComponent';
import { BaseResourceUrl } from '../common/ApiHelper';

interface ListProps {
    data: Array<{
        id: string,
        src: string,
        name: string
    }>,
    onItemClick: (id: string) => void,
    onScrollToLower?: () => void,
    noMore?: boolean,
}

class ListComponent extends PureComponent<ListProps> {
    public height = 0;
    public paddingTop = 0;
    public onScroll = (e: React.UIEvent<HTMLUListElement>) => {
        const scrollHeight = e.currentTarget.scrollHeight;
        const scrollTop = e.currentTarget.scrollTop;
        console.log('onScroll', Math.floor(scrollHeight - scrollTop - this.height - this.paddingTop));
        if (Math.floor(scrollHeight - scrollTop - this.height - this.paddingTop) <= 0) {
            console.log(123)
            this.props.onScrollToLower && this.props.onScrollToLower ();
        }
        e.stopPropagation();
    }
    componentWillMount() {
        const fontSize = parseFloat(document.getElementsByTagName('html')[0].style.fontSize!.replace('px', ''));
        this.paddingTop = (fontSize * 0.398148);
        const height = window.innerHeight - (fontSize * 1.166666) - this.paddingTop;
        this.height = height;
    }
    public render() {
        const { data, onItemClick, noMore } = this.props;
        const layout = data.map(v=> 
            <li key={v.id} onClick={onItemClick.bind(this, v.id)}>
                <a>
                    <div className="c-img">
                        {/* <AsyncImg src={v.src}/> */}
                        <img src={ BaseResourceUrl + '/'+v.src } /> 
                    </div>
                    <div className="c-tit">
                        <h2>{v.name}</h2>
                    </div>
                </a>
            </li>
        );
        if (noMore) {
            layout.push(...[<div key='nomore' style={{textAlign: 'center', width: '100%', float: 'left', padding: '1em 0'}}><span>没有更多了</span></div>]);
        }
        return (
            <div className="mb">
                <ul style={{height: this.height + 'px', overflowY: 'auto', WebkitOverflowScrolling: 'touch'}} onScroll={this.onScroll}>
                    {layout}
                </ul>
            </div>
        );
    }
}

export default ListComponent;
