import React, { PureComponent } from 'react';
import Loading from '../loading.svg';

interface ToastProps {
    message: string,
    type: number,
    oncancel?: () => void,
    onconfirm?: () => void
}

class ToastComponent extends PureComponent<ToastProps> {
    public render() {
        const { message, type, oncancel, onconfirm } = this.props;
        switch (type) {
            case 0:
                return (
                    <div style={{position: 'absolute', width: '100%', height: '100%', top: 0, zIndex: 10000, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <div style={{color: 'white', height: '30vw', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgb(0,0,0,0.5)', borderRadius: '1em', flexDirection: 'column'}}>
                            <img style={{height: '70%'}} src={Loading} alt="logo" />
                            {message}
                        </div>
                    </div>
                );
            case 1:
                return (
                    <div style={{position: 'absolute', width: '100%', height: '100%', top: 0, zIndex: 10000, display: 'flex', justifyContent: 'center', alignItems: 'center', pointerEvents: 'none'}}>
                        <div style={{color: 'white', height: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgb(0,0,0,0.5)', borderRadius: '1em', flexDirection: 'column', padding: '0.5em'}}>
                            <span style={{padding: '0.5em'}}>{message}</span>
                        </div>
                    </div>
                );
            case 2:
                return (
                    <div style={{position: 'absolute', width: '100%', height: '100%', top: 0, zIndex: 10000, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <div style={{fontSize: '0.35rem', opacity: 0, color: 'white', width: '70vw', height: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgb(78, 78, 78)', borderRadius: '1em', flexDirection: 'column', padding: '0.5em', boxShadow: 'rgb(109, 103, 103) 9px 12px 11px 0px',
                            animation: 'ShowToast 0.5s ease 0s 1 normal forwards running' }}>
                            <span style={{padding: '3em'}}>{message}</span>
                            <div style={{justifyContent: 'space-around', display: 'flex', width: '100%'}}>
                                <div style={{flexGrow: 1, textAlign: 'center', padding: '1em 0', borderRight: '1px solid #a5a5a5'}} onClick={onconfirm}>确定</div>
                                <div style={{flexGrow: 1, textAlign: 'center', padding: '1em 0'}} onClick={oncancel}>取消</div>
                            </div>
                        </div>
                    </div>
                );
        }
        
    }
}

export default ToastComponent;
