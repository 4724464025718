import React, { Component } from 'react'
import back from '../img/icon/back1.svg'
interface BackProps {
  onBackClick: () => void
}
class BackComponent extends Component<BackProps> {
  constructor(props: any) {
    super(props)
  }
  render() {
    const { onBackClick } = this.props
    return (
      <div style={{ width: '100%', position: 'fixed', top: '0' }}>
        <header
          className="read-header read-opt-header"
          style={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: 'white',
          }}
        >
          <div
            onClick={onBackClick.bind(this)}
            style={{
              textAlign: 'center',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              width: '0.5rem',
              paddingLeft: '0.3rem',
            }}
          >
            <img src={back} />
          </div>
          <div style={{ fontSize: '1.5em', padding: '0 0.2rem' }}></div>
        </header>
      </div>
    )
  }
}

export default BackComponent
