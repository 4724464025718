import React from 'react'
import BaseComponent from '../components/BaseComponent'
import { GetBookDetails, BaseResourceUrl } from '../common/ApiHelper'

class Details extends BaseComponent {
  state: {
    Data: BookDetails['Data']['bookDetails']
  } = {
    Data: {
      ClassifyTitle: '',
      collect: 0,
      likes: [],
      bookID: 0,
      imgUrl: '',
      name: '',
      writer: '',
      lookCount: 0,
      downCount: 0,
      intro: '',
      fileUrl: '',
      url: '',
    },
  }
  componentDidMount() {
    const bookid = this.GetQueryString(this.props.location.search, 'bookid')!
    this.GetBookDetailsFunc(bookid)
  }
  GetBookDetailsFunc = (bookid: string) => {
    this.ShowToast()
    GetBookDetails(bookid, (res) => {
      this.setState({ Data: res.Data.bookDetails }, () => {
        this.RemoveToast()
      })
    })
  }
  onLikeClick = (id: number) => {
    this.GetBookDetailsFunc(id.toString())
  }
  onReadOrListenClick = () => {
    if (
      this.state.Data.url !== undefined &&
      this.state.Data.url !== null &&
      this.state.Data.url.length > 0
    ) {
      window.location.href = this.state.Data.url
    } else {
      this.props.history.push(
        '/Read?id=' +
          this.state.Data.bookID +
          '&fileUrl=' +
          this.state.Data.fileUrl
      )
    }
  }
  public render() {
    const {
      ClassifyTitle,
      collect,
      likes,
      bookID,
      imgUrl,
      name,
      writer,
      lookCount,
      downCount,
      intro,
      fileUrl,
    } = this.state.Data
    const likeLayout = likes.map((v) => {
      return (
        <li key={v.bookID} onClick={this.onLikeClick.bind(this, v.bookID)}>
          <a>
            <div className="c-img">
              {/* <AsyncImg src={v.imgUrl}/> */}
              <img src={BaseResourceUrl + '/' + v.imgUrl} />
              {/* <div className="c-tip"><img src={require('../img/img22.png')} /></div> */}
            </div>
            <div className="c-tit">
              <h2>{v.name}</h2>
            </div>
          </a>
        </li>
      )
    })

    return (
      <div className="c-box">
        <div className="c-tingshuxq c-tingshuxq3">
          <div className="mt">
            <div className="c-img">
              {/* <AsyncImg src={imgUrl}/> */}
              <img src={BaseResourceUrl + '/' + imgUrl} />
            </div>
            <div className="c-tit">
              <h1>{name}</h1>
              <p>{'栏目：' + ClassifyTitle}</p>
              <p>{'作者：' + writer}</p>
              <p>{'阅读量：' + lookCount}</p>
            </div>
          </div>
          <div className="mc">
            <ul>
              <li onClick={this.onReadOrListenClick}>
                <a>
                  <div className="c-img">
                    <img src={require('../img/img37.jpg')} />
                  </div>
                  <div className="c-tit">阅读</div>
                </a>
              </li>
              {/* <li className="c-shoucang">
                                <a>
                                    <div className="c-img"><img src={require('../img/img36.jpg')} /></div>
                                    <div className="c-tit">收藏</div>
                                </a>
                            </li> */}
            </ul>
          </div>
          <div className="mb">
            <div className="c-slide">
              <h2>简介：</h2>
              <div className="bd">
                <ul>
                  <li>
                    <div className="c-jianjie">{intro}</div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="c-shu">
            <div className="mtt">猜你喜欢</div>
            <div className="mb">
              <ul>{likeLayout}</ul>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Details
