import React, { PureComponent, CSSProperties } from 'react';

interface InputProps {
    value: string,
    type: string,
    placeholder: string,
    title: string,
    btnName: string,
    btnOnClick: () => void,
    width: string,
    maxLength: number,
    // onInput: (event: React.FormEvent<HTMLInputElement>) => void
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
    name: string
}

class InputComponent extends PureComponent<InputProps> {
    public static defaultProps: Partial<InputProps> = {
        type: 'text'
    }
    public render() {
        const { Div, Span, Input, Btn } = Style;
        const { type, placeholder, title, btnName, width, maxLength, value, onChange, name, btnOnClick } = this.props;
        const span = {width: width};
        Object.assign(span, Span);
        return <div style={Div}>
            <span style={span}>{title}</span>
            <input name={name} value={value} onChange={onChange} maxLength={maxLength} style={Input} placeholder={placeholder} type={type}/>
            {
                btnName !== undefined ? <span style={Btn} onClick={btnOnClick}>{btnName}</span> : null
            }
        </div>
    }
}

const Style: {
    [key: string]: CSSProperties
} = {
    Div: {
        margin: '0.2rem 0',
        border: '1px solid darkgray',
        borderRadius: '0.1rem',
        lineHeight: '1rem',
        // padding: '0 1em',
        width: '100%',
        backgroundColor: 'white',
        display: 'flex'
    },
    Span: {
        padding: '0 0.2rem'
    } as CSSProperties,
    Input: {
        border: 0,
        flexGrow: 1,
    },
    Btn: {
        // padding: '0px 0.5em',
        backgroundColor: 'darkgray',
        color: 'white',
        width: '2.5rem',
        textAlign: 'center'
    }
}

export default InputComponent;
