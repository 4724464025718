import React,{Component} from 'react';
import { RouteComponentProps } from 'react-router';
import Toast from './ToastComponent';
import ReactDOM from 'react-dom';

export default class BaseComponent extends Component<RouteComponentProps>{
    private div:HTMLDivElement|null=null;
    public GetQueryString=(query:string,name:string)=>{
        var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)","i"); 
        var r = query.substr(1).match(reg); 
        if (r!=null) return (r[2]); return null; 
    }
    /**
     * @param message 显示内容
     * @param type 显示样式 0-loading, 1-文本, 2-带确认取消文本
     * @param duration 显示时长, 0-无限时长
     * @param oncancel 取消按钮
     * @param onconfirm 确认按钮
     */
    public ShowToast = (message = '加载中', type = 0, duration = 0, oncancel?: () => void, onconfirm?: () => void) => {
        const odiv = document.getElementById('custoast');
        if (odiv !== null) {
            return;
        }
        const div = this.div = document.createElement('div');
        div.id = 'custoast';
        document.body.appendChild(this.div);
        if (duration !== 0) {
            setTimeout(() => {
                this.RemoveElement(div);
                // document.body.removeChild(div);
            }, duration);
        }
        ReactDOM.render(<Toast message={message} type={type} oncancel={oncancel} onconfirm={onconfirm}/>, this.div);
    }
    public RemoveToast = () => {
        if (this.div) {
            this.RemoveElement(this.div);
        }
    }

    public RemoveElement = (ele: HTMLDivElement) => {
        if (ele && ele.parentElement !== null) {
            document.body.removeChild(ele);
        }
    }
}