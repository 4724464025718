import React, { PureComponent } from 'react';

interface SwiperProps {
    data: Array<{
        id: string,
        src: string
    }>
}

class SwiperComponent extends PureComponent<SwiperProps> {
    public componentDidUpdate() {
        new Swiper('.swiper-container', {
	        pagination: '.swiper-pagination',
	        nextButton: '.swiper-button-next',
	        prevButton: '.swiper-button-prev',
	        paginationClickable: true,
	        spaceBetween: 30,
	        centeredSlides: true,
	        autoplay: 3000,
	        autoplayDisableOnInteraction: false,
	        loop : true,
			loopAdditionalSlides : 1,
	    });
    }
    public componentWillReceiveProps() {
        new Swiper('.swiper-container', {
	        pagination: '.swiper-pagination',
	        nextButton: '.swiper-button-next',
	        prevButton: '.swiper-button-prev',
	        paginationClickable: true,
	        spaceBetween: 30,
	        centeredSlides: true,
	        autoplay: 3000,
	        autoplayDisableOnInteraction: false,
	        loop : true,
			loopAdditionalSlides : 1,
	    });
    }
    public componentDidMount() {
        new Swiper('.swiper-container', {
	        pagination: '.swiper-pagination',
	        nextButton: '.swiper-button-next',
	        prevButton: '.swiper-button-prev',
	        paginationClickable: true,
	        spaceBetween: 30,
	        centeredSlides: true,
	        autoplay: 3000,
	        autoplayDisableOnInteraction: false,
	        loop : true,
			loopAdditionalSlides : 1,
	    });
    }
    public render() {
        const layout = this.props.data.map(v=> 
            <div key={v.id} className="swiper-slide">
                <img src={v.src} />
                {/* <img src={require('../img/banner2.jpg')}/> */}
            </div>
        );
        console.log(layout);
        return (
            <div className="swiper-container">
                <div className="swiper-wrapper">
                    {layout}
                </div>
                <div className="swiper-pagination"/>
            </div>
        );
    }
}

export default SwiperComponent;
