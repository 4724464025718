let msg: () => void

const BaseUrl = ''
const BaseResourceUrl = ''

const Post = (
  url: string,
  done: (res: any) => void,
  error?: (res: any) => void,
  isCache?: boolean,
  isMax?: boolean,
  isRe?: boolean,
  isFullLoading?: boolean
) => {
  const cache = localStorage.getItem(url)
  const node = document.getElementById('loading-m')
  if (isFullLoading) {
    node!.style.display = 'block'
  }
  if (cache !== null && isCache) {
    done(JSON.parse(cache))
    if (isFullLoading) {
      node!.style.display = 'none'
    }
  } else {
    fetch(BaseUrl + (isMax ? '/MaxApi' : '/AppWebService.asmx') + url, {
      method: 'GET',
      mode: 'cors',
    })
      .then((res) => {
        return res.json()
      })
      .then((res: any) => {
        if (msg) {
          msg()
        }
        done(res)
        if (isCache) {
          localStorage.setItem(url, JSON.stringify(res))
        }
        if (isFullLoading) {
          node!.style.display = 'none'
        }
      })
      .catch((res: any) => {
        error === undefined ? ErrorDefault(res) : error(res)
        if (isRe) {
          if (msg) {
            msg()
          }
          // Toast.loading('网络异常，正在重试第:' + index++ + '次', 0);
          setTimeout(() => {
            Post(url, done, error, isCache, isMax, isRe)
          }, 5000)
        } else {
          // Toast.fail('网络异常, 获取数据失败', 5);
        }
        if (isFullLoading) {
          node!.style.display = 'none'
        }
      })
  }
}

/**
 * # 获取首页
 * @param type
 * @param done
 * @param error
 */
const GetLookHome = (
  type: string,
  done: (res: LookHome) => void,
  error?: (res: any) => void
) => {
  const userid = window.userID
  let params = '/lookHome?userID=' + userid + '&type=' + type + ''
  Post(params, done, error, false, false, false, false)
}
/**
 * 登录
 * @param account
 * @param password
 * @param done
 * @param error
 */
const Login = (
  account: string,
  password: string,
  done: (res: Login) => void,
  error?: (res: any) => void
) => {
  let params = '/libraryLoginPaid?username=' + account + '&password=' + password
  Post(params, done, error, false, false, false, false)
}

/**
 * 注册
 * @param account
 * @param passpwd
 * @param name
 * @param done
 * @param error
 */
const Register = (
  account: string,
  passpwd: string,
  name: string,
  done: (res: Register) => void,
  error?: (res: any) => void
) => {
  let params =
    '/register?userid=' +
    account +
    '&username=' +
    name +
    '&userpwd=' +
    passpwd +
    ''
  Post(params, done, error, false, false, false, false)
}

/**
 * 获取分类
 * @param classifyId
 * @param sorttype
 * @param pageIndex
 * @param done
 * @param error
 */
const GetClassifyItem = (
  classifyId: string,
  sorttype: number,
  pageIndex: number,
  done: (res: ClassifyItem) => void,
  error?: (res: any) => void
) => {
  const userid = window.userID
  let params =
    '/classifyItem?userID=' +
    userid +
    '&classifyId=' +
    classifyId +
    '&pageIndex=' +
    pageIndex +
    '&pageSize=30&sorttype=' +
    sorttype +
    '&order=desc' +
    '&type=1'
  Post(params, done, error, false, false, false, false)
}
/**
 * 搜索
 * @param done
 * @param error
 */
const GetSearch = (
  keyword: string,
  type: string,
  sorttype: number,
  pageIndex: number,
  done: (res: ClassifyItem) => void,
  error?: (res: any) => void
) => {
  const userid = window.userID
  let params =
    '/search?userID=' +
    userid +
    '&keyword=' +
    keyword +
    '&type=' +
    type +
    '&sorttype=' +
    sorttype +
    '&pageIndex=' +
    pageIndex +
    '&order=desc' +
    '&pageSize=30'
  Post(params, done, error, false, false, false, false)
}
/**
 * 新书推荐
 * @param done
 * @param error
 */
const NewArrivals = (
  done: (res: NewArrivals) => void,
  error?: (res: any) => void
) => {
  let params = '/NewArrivals'
  Post(params, done, error, false, false, false, false)
}

/**
 * # 获取图书详情
 * @param bookID
 * @param done
 * @param error
 */
const GetBookDetails = (
  bookID: string,
  done: (res: BookDetails) => void,
  error?: (res: any) => void
) => {
  const userid = window.userID
  let params = '/bookDetails?userid=' + userid + '&bookID=' + bookID
  Post(params, done, error, false, false, false, false)
}

const ErrorDefault = (res: any) => {
  console.log(res)
}

export {
  BaseResourceUrl,
  Login,
  GetLookHome,
  Register,
  GetClassifyItem,
  NewArrivals,
  GetBookDetails,
  GetSearch,
}
