import React from 'react';
import BaseComponent from '../components/BaseComponent';
import { GetClassifyItem,GetSearch } from '../common/ApiHelper';//GetSearch搜索
import List from '../components/ListComponent';

class Search extends BaseComponent {
    state: {
        listData: Array<{
            id: string,
            src: string,
            name: string
        }>,
        mcIndex: number,
        noMore: boolean
    } = {
        listData: [],
        mcIndex: 3,
        noMore: false
    }
    public classifyid: string | undefined;
    public keyword: string | undefined;
    public type: string | undefined;
    public pageIndex = 1;
    public isLoading = false;
    public isDone = false;
    componentDidMount() {
        this.classifyid = this.GetQueryString(this.props.location.search,'classifyid')!;
        // console.log(this.classifyid);
        this.keyword = this.GetQueryString(this.props.location.search,'keyword')!;
        this.type = this.GetQueryString(this.props.location.search,'type')!;
        if (this.classifyid !== null) {
            this.GetData();
        }
        else if (this.keyword !== null) {
            this.Search();
        }
    }
    Search = () => {
        if (this.isLoading || this.isDone) {
            return;
        }
        this.isLoading = true;
        this.ShowToast();
        GetSearch(this.keyword!, this.type!, this.state.mcIndex, this.pageIndex, this.Done);
    }
    GetData = () => {
        if (this.isLoading || this.isDone) {
            return;
        }
        this.isLoading = true;
        this.ShowToast();
        GetClassifyItem(this.classifyid!, this.state.mcIndex, this.pageIndex, this.Done)
    }
    Done = (res: ClassifyItem) => {
        const listData = res.Data.books.map(v=>{
            return {
                id: v.bookID.toString(),
                src: v.imgUrl,
                name: v.name
            }
        });
        this.RemoveToast();
        let noMore = false;
        if (listData.length <= 0) {
            this.ShowToast('下面没有了', 1, 1000);
            this.isDone = true;
            noMore = true;
        }
        const temp = this.state.listData.slice();
        temp.push(...listData);
        this.setState({listData: temp, noMore: noMore}, () => {
            this.isLoading = false;
        });
    }
    onItemClick = (id: string) => {
        switch (this.type) {
            case '2':
                this.props.history.push('/Listen?bookid=' + id);
            break;
            default:
                this.props.history.push('/Details?bookid=' + id);
            break;
        }
        // this.props.history.push('/Details?bookid=' + id);
    }
    /**
     * 默认 点击量 最新
     */
    onMCClick = (index: number) => {
        this.isDone = false;
        this.pageIndex = 1;
        this.setState({mcIndex: index, listData: []}, () => {//bug 不走里面的方法
            if (this.classifyid !== null) {//更多
                this.GetData();
            }
            else if (this.keyword !== null) {//搜索
                this.Search();
            }
        });
    }
    onScrollToLower = () => {
        this.pageIndex++;
        console.log(1);
        if (this.classifyid !== null) {
            this.GetData();
        }
        else if (this.keyword !== null) {
            this.Search();
        }
    }
    public render() {
        const { listData, mcIndex, noMore } = this.state;
        return (
            <div className="c-box">
                <div className="c-shu c-list">
                    <div className="mc">
                        <ul>
                            <li className={ mcIndex === 1 ? 'on' : '' } onClick={this.onMCClick.bind(this, 1)}>
                                <a>
                                    <div className="c-tit">默认</div>
                                </a>
                            </li>
                            <li className={ mcIndex === 2 ? 'on' : '' } onClick={this.onMCClick.bind(this, 2)}>
                                <a>
                                    <div className="c-tit">点击量</div>
                                    <div className="c-img"></div>
                                </a>
                            </li>
                            <li className={ mcIndex === 3 ? 'on' : '' } onClick={this.onMCClick.bind(this, 3)}>
                                <a>
                                    <div className="c-tit">最新</div>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="c-h126"></div>
                    <List data={listData} onItemClick={this.onItemClick} onScrollToLower={this.onScrollToLower} noMore={noMore}/>
                </div>
            </div>
        );
    }
}

export default Search;
