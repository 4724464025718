import React, { PureComponent } from 'react';
//import AsyncImg from './AsyncImgComponent';
import { BaseResourceUrl } from '../common/ApiHelper';

interface CardProps {
    data: Array<{
        id: string,
        src: string,
        name: string
    }>,
    name: string,
    id: string,
    onMoreClick: () => void,
    onItemClick: (id: string) => void
}

class CardComponent extends PureComponent<CardProps> {
    public render() {
        const { data, name, id, onItemClick, onMoreClick } = this.props;
        const layout = data.map(v=> 
            <li key={v.id} onClick={onItemClick.bind(this, v.id)}>
                <a>
                    <div className="c-img">
                        {/* <AsyncImg src={v.src}/> */}
                        <img src={ BaseResourceUrl + '/'+v.src } /> 
                        {/* <div className="c-tip"><img src={require('../img/img22.png')} /></div> */}
                    </div>
                    <div className="c-tit">{v.name}</div>
                </a>
            </li>
        );
        return (
            <div className="c-shu">
                <div className="mt">
                    <h1>{name}</h1>
                    <a>
                        <div className="c-tit" style={{cursor:'pointer'}} onClick={onMoreClick}>更多</div>
                        <div className="c-img"><img src={require('../img/img20.jpg')} /></div>
                    </a>
                </div>
                <div className="mb">
                    <ul>
                        {layout}
                    </ul>
                </div>
            </div>
        );
    }
}

export default CardComponent;
