import React, { Component } from 'react';
import { BaseResourceUrl } from '../common/ApiHelper';

interface ClassProps {
    data: Array<{
        id: string,
        src: string,
        name: string,
        items:Array<{id: string, src: string, name: string}>
    }>,
    onItemClick: (id: string) => void
}

class ClassComponent extends Component<ClassProps> {
    state = {
        showMoreClass: ''
    }
    public onMoreClick = () => {
        if (this.state.showMoreClass === 'on') 
            this.setState({showMoreClass: ''});
        else
            this.setState({showMoreClass: 'on'});
    }
    public render() {
        const { showMoreClass } = this.state;
        const { data, onItemClick } = this.props;
        let MoreOrNoMore = '收起';
        let li=(num:string)=>{
            if (num == "0")
            {
                return <i className="iconfont icon-lilun"></i>;
            }
            else if (num == "1")
            {
                return <i className="iconfont icon-zongjiao"></i>;
            }
            else if (num == "2")
            {
                return <i className="iconfont icon-shehui"></i>;
            }
            else if (num == "3")
            {
                return <i className="iconfont icon-falv"></i>;
            }
            else if (num == "4")
            {
                return <i className="iconfont icon-1122-v2-15"></i>;
            }
            else if (num == "5")
            {
                return <i className="iconfont icon-jingjishehui"></i>;
            }
            else if (num == "6")
            {
                return <i className="iconfont icon-whjs"></i>;
            }
            else if (num == "7")
            {
                return <i className="iconfont icon-yuyan"></i>;
            }
            else if (num == "8")
            {
                return <i className="iconfont icon-wenxueqimeng"></i>;
            }
            else if (num == "9")
            {
                return <i className="iconfont icon-shanzi"></i>;
            }
            else if (num == "10")
            {
                return <i className="iconfont icon-lishi"></i>;
            }
            else if (num == "11")
            {
                return <i className="iconfont icon-ziranhuanjing"></i>;
            }
            else if (num == "12")
            {
                return <i className="iconfont icon-shuxue_maths"></i>;
            }
            else if (num == "13")
            {
                return <i className="iconfont icon-kexue"></i>;
            }
            else if (num == "14")
            {
                return <i className="iconfont icon-shengwu"></i>;
            }
            else if (num == "15")
            {
                return <i className="iconfont icon-yiliao"></i>;
            }
            else if (num == "16")
            {
                return <i className="iconfont icon-nongye"></i>;
            }
            else if (num == "17")
            {
                return <i className="iconfont icon-gongyeline"></i>;
            }
            else if (num == "18")
            {
                return <i className="iconfont icon-jiaotongyunshu"></i>;
            }
            else if (num == "19")
            {
                return <i className="iconfont icon-plane"></i>;
            }
            else if (num == "20")
            {
                return <i className="iconfont icon-huanjing"></i>;
            }
            else if (num == "21")
            {
                return <i className="iconfont icon-tushu"></i>;
            }
            else
            {
                return <i className="iconfont icon-lilun"></i>;
            }
        }
        let layout = data.map(v=>
            <li key={v.id} onClick={onItemClick.bind(this,v.id)}>
                <a>
                    <div className="c-img">
                    {li(v.id)}                    
                    {/* <img src={require("../img/"+v.src)} /> */}
                    {/* <img src={BaseResourceUrl+v.src} /> */}
                    </div>
                    <div className="c-tit">{(v.name.length>2?v.name.substr(0,2):v.name)}</div>
                </a>
            </li>
        );
        if (showMoreClass === '') {
            layout = layout.slice(0, 9);
            MoreOrNoMore = '更多';
        }

        const more = <li onClick={this.onMoreClick} key='more' className="c-more">
            <a>
                <div className="c-img"><img src={require('../img/img19.jpg')}/></div>
                <div className="c-tit">{MoreOrNoMore}</div>
            </a>
        </li>;
        // layout.splice(9, 0, more);
        if (data.length > 10) {
            layout[layout.length] = more;
        }
        
        return (
            <div className="c-index2-menu">
                <ul className={showMoreClass}>
                    {layout}
                </ul>		    	
            </div>
        );
    }
}

export default ClassComponent;
