import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { Router, Route, Switch } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { Login as LoginFunc } from './common/ApiHelper'
import './index.css'
import registerServiceWorker from './registerServiceWorker'

import App from './App'
import Login from './pages/Login'
import Search from './pages/Search'
import Details from './pages/Details'
import Read from './pages/Read'
const userID = localStorage.getItem('userID')
let account: string = ''
let password: string = ''
window.userID = userID!
// window.userID = '10001'

const history = createBrowserHistory()
//路由控制
const router = (
  <Router history={history}>
    <Switch>
      <Route path="/" exact={true} component={App} />
      <Route path="/Login" component={Login} />
      <Route path="/Search" component={Search} />
      <Route path="/Details" component={Details} />
      <Route path="/Read" component={Read} />
    </Switch>
  </Router>
)

function getQueryVariable(variable: string) {
  let query = window.location.search.substring(1)
  let vars = query.split('&')
  for (let i = 0; i < vars.length; i++) {
    let pair = vars[i].split('=')
    if (pair[0] === variable) {
      return pair[1]
    }
  }
  return ''
}
if (isNaN(parseInt(window.userID))) {
  account = getQueryVariable('idCard')
  password = getQueryVariable('psw')
  if (account === '' || password === '') {
    history.replace(
      '/Login?rd=' + window.location.pathname + window.location.search
    )
  }
}
ReactDOM.render(router, document.getElementById('root') as HTMLElement, () => {
  if (account !== '' && password !== '') {
    LoginFunc(
      account,
      password,
      (res) => {
        if (res.code === 1) {
          localStorage.setItem('userID', '1')
          window.userID = '1'
          history.replace('/')
        } else {
          if (isNaN(parseInt(window.userID))) {
            history.replace('/Login')
          }
          const node = document.getElementById('loading-m')
          if (node !== null) {
            node.style.display = 'none'
          }
        }
      },
      () => {
        if (isNaN(parseInt(window.userID))) {
          history.replace('/Login')
        }
        const node = document.getElementById('loading-m')
        if (node !== null) {
          node.style.display = 'none'
        }
      }
    )
  } else {
    if (isNaN(parseInt(window.userID))) {
      history.replace('/Login')
    }
    const node = document.getElementById('loading-m')
    if (node !== null) {
      node.style.display = 'none'
    }
  }
})
registerServiceWorker()
