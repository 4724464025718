import React from 'react'
import BaseComponent from '../components/BaseComponent'
import { BaseResourceUrl } from '../common/ApiHelper'
import Back from '../components/BackComponent'

class Read extends BaseComponent {
  state = {
    url: '',
  }
  componentDidMount() {
    const fileUrl = this.GetQueryString(this.props.location.search, 'fileUrl')!
    if (fileUrl.indexOf('.pdf') !== -1) {
      this.setState({
        url: 'pdfjs/web/viewer.html?file=' + BaseResourceUrl + fileUrl + '',
      })
    } else {
      this.setState({
        url: 'djvu/djvu.html?file=' + BaseResourceUrl + fileUrl + '',
      })
    }
  }
  public back = () => {
    this.props.history.goBack()
  }
  render() {
    return (
      <div>
        <Back onBackClick={this.back} />
        <iframe
          ref="iframe"
          title="iframe"
          frameBorder={0}
          src={this.state.url}
          style={{
            width: '100%',
            height: '96vh',
            marginTop: '4vh',
          }}
        />
      </div>
    )
  }
}
export default Read
